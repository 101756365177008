.perfect_gift {
  background-color: #fff;
  padding-bottom: 40px;
  .perfect_gift_header {
    padding-top: 0px;
    padding-bottom: 20px;
    h2 {
      color: #000;
      font-weight: 600;
      font-size: 25px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      font-family: "Lato", sans-serif;
      padding-top: 20px;
    }
    p {
      color: #727272;
      text-align: center;
      font-size: 18px;
      font-family: "Lato", sans-serif;
      margin-bottom: 0px;
    }
  }
  .col_11 {
    flex: 0 0 auto;
    width: 20%;
    a {
      display: block;
      cursor: pointer;
      clear: both;
    }
  }
  .gift_jwellery {
    background-color: #e6e6e6;
    span {
      position: relative;
      width: 100% !important;
    }
    img {
      width: 100% !important;
      height: 312px !important;
      object-fit: cover;
      overflow: hidden;
    }
    .gift_text {
      padding: 30px 0;
      text-align: center;
      .h3_sub {
        font-size: 22px;
        color: #000;
        text-transform: uppercase;
      }
    }
    &:hover {
      background-color: #ff1933;
    }

    &:hover .h3_sub {
      color: #fff;
    }
  }
}
.btn_get_started {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 30px;
  padding-right: 100px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: #000;
  -webkit-animation-delay: 0.8s;
  border: 1px solid #000;
  animation-delay: 0.8s;
  background: transparent;
  text-align: left;
  width: 315px;
  text-transform: uppercase;
  background-image: url("../assets/images/arrow.png");
  background-repeat: no-repeat;
  background-position: 190px 14px;
  margin-top: 5px;
  .d_block_hover {
    display: none !important;
  }
  &:hover {
    color: #fff;
    background-color: #ff1933;
    background-image: url("../assets/images/arrow-white.png");
    border: 1px solid #ff1933;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .perfect_gift {
    background-color: #fff;
    padding-bottom: 40px;

    .gift_jwellery {
      background-color: #e6e6e6;
      span {
        position: relative;
        width: 100% !important;
      }
      img {
        width: 100% !important;
        height: 180px !important;
        object-fit: cover;
        overflow: hidden;
      }
      .gift_text {
        padding: 15px 0;
        text-align: center;
        .h3_sub {
          font-size: 16px;
          color: #000;
          text-transform: uppercase;
          margin: 0px;
        }
      }
    }
  }
}
